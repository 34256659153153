
.reg-img-con img{
    width: 100%;
    border-radius: 6px;
}
.reg-img-con .slick-prev{
    left: -17px;
    background: white;
    border-radius: 50%;
}
.reg-img-con .slick-next{
    right: -17px;
    background: white;
    border-radius: 50%;
}
.reg-img-con .slick-prev:before,
.reg-img-con .slick-next:before {
  color: rgb(125, 111, 111);
}